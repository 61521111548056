/* 
** The Header Media Queries **
** Tweak as per your needs **
*/
.brand {
  font-weight: bold;
  font-size: 20px;
 }

.site-header__start {
  display: block;
  float: left;
  height: 28px;
}

.site-header__middle {
  display: block;
  float: left;
  margin-left: 20px;
  height: 28px;
}

.site-header__end {
  float: right;
  height: 28px;
}

.site-header {
  position: relative;
  background-color: #93b9ff;
}

a.brand {
  text-decoration: none;
  color: #222;
}
  
.site-header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: top;
  padding-top: 1rem;
  padding-bottom: 1rem; }
  @media (min-width: 660px) {
    .site-header__wrapper {
      padding-top: 0;
      padding-bottom: 0; } }
@media (max-width: 659px) {
  .site-header__end {
    padding-right: 4rem; } }

@media (min-width: 660px) {
  .nav__wrapper {
    display: flex; } }

@media (max-width: 659px) {
  .nav__wrapper {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: -1;
    background-color: #d9f0f7;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-100%);
    transition: transform 0.3s ease-out, opacity 0.3s ease-out; }
    .nav__wrapper.active {
      visibility: visible;
      opacity: 1;
      transform: translateY(0); } }

.nav__wrapper {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav__item {
  float: left
}

.nav__item a {
  display: block;
  padding: 5px;
  text-decoration: none;
  color: #222;
 }

.nav__toggle {
  display: none; }
  @media (max-width: 659px) {
    .nav__toggle {
      display: block;
      position: absolute;
      right: 1rem;
      top: 1rem; } }


.app {
	height: 100vh;
	background-color: #7887ab;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content 1fr min-content;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "app-header"
    "app-pre-body"
    "app-body"
    "app-footer";
}

.app-header { grid-area: app-header; width: 100vw; }

.app-pre-body { grid-area: app-pre-body; }

div.app-pre-body {
  padding: 10px;
  display: grid;
  grid-template-columns: 120px 80px 30px;
  border-bottom: #061539 solid 2px;
  margin-bottom: 10px;
}


.app-body {
  grid-area: app-body;
}

.app-footer {
  grid-area: app-footer;
	background-color: #7887ab;
  justify-content: center;
 }

div.clearance_summary {
    background-color: white;
    color: black;
  }

  div.clearance_summary_selected {
    background-color: black;
    color: white;
  }

  div.Right-panel {
    grid-column: 2;
    grid-row: 1;
    margin: 0 0 0 5px ;
    padding: 10px;
    background-color: rgba(230, 250, 255, 0.5);
    }

    div.Left-panel {
      grid-column: 1;
      grid-row: 1;
      margin: 0;
      padding: 10px;
      background-color: rgba(230, 250, 255, 0.5);
      }
  
    .Clearance-panel {
    min-height: 486px;
  }
  
  div.App-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  div.clearance_list {
    grid-column: 1;
    grid-row: 1;
  }

  div.flight-plan {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-color: black;
    color:white;
    width: 320px;
    text-align: left;
  }

  .aircraft {
    grid-column: 1 / 2;
    grid-row: 1;  
  }

  .time {
    grid-column: 2 / 3;
    grid-row: 1;  
    text-align: center;
  }
  
  .altitude {
    grid-column: 3 / 4;
    grid-row: 1;  
    text-align: right;
  }
  
  .route {
    grid-column: 1 / 4;
    grid-row: 2;  
  }
  
  .transparent-button {
    border: none;
    background-color: rgba(230, 250, 255, 0.0);
  }
  
/* controls */
.controls, .controls li {
	padding:0;
	margin:0;
}
.controls {
	display:block;
	list-style-type:none;
	overflow:hidden;
	background:transparent;
}
.controls li {
	float:left;
	width:10%;
	margin-left:0.3%;
}
.controls li:first-child {
	margin-left:0;
}
.controls .progress {
	width:38%;
	cursor:pointer;
}
.controls button {
	width:100%;
	text-align:center;
	overflow:hidden;
	white-space:nowrap;
  	text-overflow:ellipsis;
}
.controls progress {
	display:block;
	width:100%;
	height:20px;
	height:1.25rem;
	margin-top:2px;
	margin-top:0.125rem;
	border:1px solid #aaa;
	overflow:hidden;
	-moz-border-radius:5px;
	-webkit-border-radius:5px;
	border-radius:5px;
}
.controls progress span {
	width:0%;
	height:100%;
	display:inline-block;
	background-color:#2a84cd;	
}

.tip-icon {
  max-width: 32px;
  height: auto;
}
