/* Colors:
// Base: 2e4172
// light->dark
// 7887ab
// 4f618f
// 162856
// 061539
*/

div.app-body {
	background-color: #7887ab;
	display: grid; 
	grid-template-columns: min-content max-content; 
	grid-template-rows: min-content min-content auto; 
	gap: 0px 20px; 
	grid-template-areas: 
		"graph-header graph-header"
		"loading graph"
		"loading loading-footer"; 
}

@media screen and (max-width: 768px) {
	div.app-body {
		grid-template-columns: max-content; 
		grid-template-rows: min-content min-content min-content auto; 
		gap: 0px 20px; 
		grid-template-areas: 
			"graph-header"
			"loading"
			"loading-footer"
			"graph";
	}
	}

.graph {
	grid-area: graph;
	align-self: flex-start;
	max-width: 80vh;
}

.graph-header { grid-area: graph-header; }
.loading {
	grid-area: loading;
	margin-top: 10px;
	margin-left: 10px;
}

.loading-footer {
	grid-area: loading-footer;
	align-self: flex-start;
}

.graph-header {
	text-align: center;
}

.alert-error{
	color: white;
	background: red;
	padding-left: 5px;
}

.alert-warning {
	color: white;
	background: orange;
	padding-left: 5px;
}

.alert-ok {
	color: green;
	padding-left: 5px;
}

.atis-values {
	grid-row: 1;
    display: grid;
    grid-template-columns: min-content max-content max-content max-content;
  }

.gap-before {
	margin-top: 1ex;
}

.edit:hover,
.edit:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}
.col0 {
	grid-column: 1;
	margin-top: 1ex;
	margin-bottom: 2px;
	padding-bottom: 8px;
	padding-right: 4px;
	padding-left: 1rem;
	text-align: left;
	font-size: 14px;
}
.col-border {
	align-self: flex-end;
	border-bottom: 1px solid rgba(0, 0, 0, 0.8);
}

.col1 {
	grid-column: 2;
	margin-top: 1ex;
	margin-bottom: 2px;
	padding-bottom: 8px;
	padding-right: 4px;
	padding-left: 1rem;
	text-align: left;
	font-size: 14px;
}

.col1.right {
	text-align: right;
}

.col2 {
	grid-column: 3;
	margin-top: 1ex;
	margin-bottom: 2px;
	padding-bottom: 8px;
	padding-right: 4px;
	padding-left: 1rem;
	text-align: left;
	font-size: 14px;
}

.col3 {
	grid-column: 4;
	margin-top: 1ex;
	margin-bottom: 2px;
	padding-bottom: 8px;
	padding-right: 4px;
	padding-left: 1rem;
	text-align: left;
	font-size: 14px;
}

div.input-form {
	grid-row: 1/2;
    display: grid;
    grid-template-columns: 120px 80px min-content min-content 30px max-content;
  }

.form-span-1 {
	grid-column: 1/4;
	margin-bottom: 2px;
	margin-right: 4px;
	margin-left: 0;
}

.form-label {
	grid-column: 1;
	margin-bottom: 2px;
	margin-right: 4px;
	margin-left: 1rem;
	text-align: right;
	font-size: 14px;
}

.uppercase {
	text-transform: uppercase;
}

.form-label-2 {
	grid-column: 5;
	margin-bottom: 2px;
	margin-right: 4px;
	margin-left: 0;
	text-align: right;
	font-size: 14px;
}

.form-span-2-3 {
	grid-column: 5/8;
	margin-bottom: 2px;
	margin-right: 4px;
	margin-left: 0;
}

.form-notes {
	width: 380px;
}

.radio-label {
	margin-right: 6px;
	font-size: 14px;
}

.form-aircraft {
	grid-column: 2/3;
	margin-bottom: 2px;
}

.form-value {
	grid-column: 2;
	margin-bottom: 2px;
}

.form-value-2 {
	grid-column: 5;
	margin-bottom: 2px;
	margin-left: 10px;
}

.form-units {
	grid-column: 3;
	margin-bottom: 2px;
	margin-left: 4px;
	font-size: 14px;
	white-space: nowrap;
}

.form-units-2 {
	grid-column: 6;
	margin-bottom: 2px;
	margin-left: 4px;
	font-size: 14px;
}

.modal {
	display: block;
	position: fixed;
	z-index: 1;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0,0,0); /* Fallback color */
	background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
	background-color: #fefefe;
	margin: 15% auto; /* 15% from the top and centered */
	padding: 20px;
	border: 1px solid #888;
	width: 80%; /* Could be more or less, depending on screen size */
}
  
/* The Close Button */
.close {
	color: #aaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
}

.close:hover,
.close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}