.App-about {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-logo {
  position: absolute;
  top: 8px;
  left: 16px;
  height: 64px;
  width: auto;
}

.App-header {
  background-image: url('img/clouds1.jpg');
  background-size: auto 100%;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

@media only screen and (min-width: 1024px) {
  .App-header {
    background-size: 100% 100%;
  }
}

.App-panel {
  margin: 10%;
  padding: 10px;
  background-color: rgba(230, 250, 255, 0.5);
}

.App-link {
  color: #61dafb;
}
