/* Colors:
// Base: 2e4172
// light->dark
// 7887ab
// 4f618f
// 162856
// 061539
*/

div.app-body {
	background-color: #7887ab;
	display: grid; 
	grid-template-columns: min-content max-content; 
	grid-template-rows: min-content min-content auto; 
	gap: 0px 20px; 
	grid-template-areas: 
		"graph-header graph-header"
		"loading graph"
		"loading loading-footer"; 
}

@media screen and (max-width: 768px) {
	div.app-body {
		grid-template-columns: max-content; 
		grid-template-rows: min-content min-content min-content auto; 
		gap: 0px 20px; 
		grid-template-areas: 
			"graph-header"
			"loading"
			"loading-footer"
			"graph";
	}
	}

.graph {
	grid-area: graph;
	align-self: flex-start;
	max-width: 80vh;
}

.graph-header { grid-area: graph-header; }
.loading {
	grid-area: loading;
	margin-top: 10px;
	margin-left: 10px;
}

.loading-footer {
	grid-area: loading-footer;
	align-self: flex-start;
}

.graph-header {
	text-align: center;
}

.alert-error{
	color: white;
	background: red;
	padding-left: 5px;
}

.alert-warning {
	color: white;
	background: orange;
	padding-left: 5px;
}

.alert-ok {
	color: green;
	padding-left: 5px;
}

.wb-chart {
	max-width: 400px;
}

.input-form {
	grid-row: 1/2;
    display: grid;
    grid-template-columns: 120px 80px min-content min-content 80px min-content min-content 80px min-content;
  }

.gap-before {
	margin-top: 1ex;
}

.form-header {
	grid-column: 1;
	margin-bottom: 2px;
}

.form-label {
	grid-column: 1;
	margin-bottom: 2px;
	margin-right: 4px;
	margin-left: 1rem;
	text-align: right;
	font-size: 14px;
}

.form-label-2 {
	grid-column: 4;
	margin-bottom: 2px;
	margin-right: 4px;
	margin-left: 0;
	text-align: right;
	font-size: 14px;
}

.form-span-2-3 {
	grid-column: 4/10;
	margin-bottom: 2px;
	margin-right: 4px;
	margin-left: 0;
}

.form-label-3 {
	grid-column: 7;
	margin-bottom: 2px;
	margin-right: 4px;
	margin-left: 0;
	text-align: right;
	font-size: 14px;
}

.form-notes {
	width: 380px;
}

.radio-label {
	margin-right: 6px;
	font-size: 14px;
}

.form-aircraft {
	grid-column: 2/3;
	margin-bottom: 2px;
}

.form-value {
	grid-column: 2;
	margin-bottom: 2px;
}

.form-value-2 {
	grid-column: 5;
	margin-bottom: 2px;
}

.form-value-3 {
	grid-column: 8;
	margin-bottom: 2px;
}

.form-units {
	grid-column: 3;
	margin-bottom: 2px;
	margin-left: 4px;
	font-size: 14px;
}

.form-units-2 {
	grid-column: 6;
	margin-bottom: 2px;
	margin-left: 4px;
	font-size: 14px;
}

.form-units-3 {
	grid-column: 9;
	margin-bottom: 2px;
	margin-left: 4px;
	font-size: 14px;
}
