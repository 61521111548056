.ClearancePlayer {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  .App-logo {
    position: absolute;
    top: 8px;
    left: 16px;
    height: 64px;
    width: auto;
  }
  
  .App-header {
    background-image: url('../img/clouds1.jpg');
    background-size: auto 100%;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
  }
  
  @media only screen and (min-width: 1024px) {
    .App-header {
      background-size: 100% 100%;
    }
  }
   
  .App-link {
    color: #61dafb;
  }

  div.clearance_summary {
    background-color: white;
    color: black;
  }

  div.clearance_summary_selected {
    background-color: black;
    color: white;
  }

  div.App-panel {
    grid-column: 2;
    grid-row: 1;
    margin: 0px;
  }

  .Clearance-panel {
    min-height: 486px;
  }
  
  div.App-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  div.clearance_list {
    grid-column: 1;
    grid-row: 1;
  }

  div.flight-plan {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-color: black;
    color:white;
    width: 320px;
    text-align: left;
  }

  .aircraft {
    grid-column: 1 / 2;
    grid-row: 1;  
  }

  .time {
    grid-column: 2 / 3;
    grid-row: 1;  
    text-align: center;
  }
  
  .altitude {
    grid-column: 3 / 4;
    grid-row: 1;  
    text-align: right;
  }
  
  .route {
    grid-column: 1 / 4;
    grid-row: 2;  
  }
  
  .transparent-button {
    border: none;
    background-color: rgba(230, 250, 255, 0.0);
  }
  
/* controls */
.controls, .controls li {
	padding:0;
	margin:0;
}
.controls {
	display:block;
	list-style-type:none;
	overflow:hidden;
	background:transparent;
}
.controls li {
	float:left;
	width:10%;
	margin-left:0.3%;
}
.controls li:first-child {
	margin-left:0;
}
.controls .progress {
	width:38%;
	cursor:pointer;
}
.controls button {
	width:100%;
	text-align:center;
	overflow:hidden;
	white-space:nowrap;
  	text-overflow:ellipsis;
}
.controls progress {
	display:block;
	width:100%;
	height:20px;
	height:1.25rem;
	margin-top:2px;
	margin-top:0.125rem;
	border:1px solid #aaa;
	overflow:hidden;
	-moz-border-radius:5px;
	-webkit-border-radius:5px;
	border-radius:5px;
}
.controls progress span {
	width:0%;
	height:100%;
	display:inline-block;
	background-color:#2a84cd;	
}

.tip-icon {
  max-width: 32px;
  height: auto;
}
